import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import moment from 'moment';
import { twoDigit } from '@proman/utils';
import { FilterService } from '@proman/services/filter.service';
import { EventEmitter, Output } from '@angular/core';
import { SelectOption } from '@proman/interfaces/object-interfaces';

@Component({
    selector: 'pm-monthpicker',
    template: `
        <div fxLayout="row" fxFlex class="Monthpicker">
            <pro-select [value]="year"
                    [config]="{ label: (config.label || 'year'), key: 'id' }"
                    [options]="yearOptions"
                    (onChange)="setYear($event)"
                    fxFlex="75px"
            ></pro-select>
            <pro-select [value]="month"
                    [config]="{ label: (config.label ? '' : 'month'), key: 'id' }"
                    [options]="monthOptions"
                    (onChange)="setMonth($event)"
                    fxFlex
            ></pro-select>
        </div>
    `,
    styles: [`
        .Monthpicker {
            padding: 8px;
        }
        .Monthpicker > pro-select:first-child {
            margin-right: 8px;
        }
    `]
})

export class MonthpickerComponent implements OnInit {
    @Input() value: any;
    @Input() config: { label?: string } = {};
    @Output() onChange: EventEmitter<string> = new EventEmitter();

    year: string;
    month: string;
    yearOptions: SelectOption[];
    monthOptions: SelectOption[];

    constructor(
        private Filter: FilterService,
    ) {
        this.yearOptions = [];

        for (let i = 0; i < 100; i++) {
            const date = moment();
            const year = date.add(i * -1 + 5, 'year').year().toString();

            this.yearOptions.push({ name: year, id: year });
        }

        this.monthOptions = this.Filter.translate('_month_names')
            .split(' ')
            .map((name: string, index: number) => ({
                name, id: twoDigit(index + 1)
            }));
    }

    ngOnInit(): void {
        if (this.isValid()) {
            [this.year, this.month] = this.value.split('-');

        } else if (!this.value || this.isValid()) {
            this.initValue();

        }
    }

    initValue() {
        const date = moment();
        this.year = date.year().toString();
        this.month = twoDigit(date.month());

        this.update(`${this.year}-${this.month}`);
    }

    setYear(year: string) {
        this.year = year;
        this.update(`${year}-${this.month}`);
    }

    setMonth(month: string) {
        this.month = month;
        this.update(`${this.year}-${month}`);
    }

    update(value: string) {
        this.value = value;
        this.onChange.emit(this.value);
    }

    isValid = () => {
        if (this.value instanceof Object) {
            this.value = this.value.period;
        }
        return this.value?.match(/\d{4}-\d{2}/)?.length > 0;
    };

}
